import { Context } from "./index";
import { calculateDuration, sortEvents } from "../utils";
import { IFilter } from "./state";

export const onInitializeOvermind = (context: Context) => {
    let { actions, effects, state } = context;
    let hostname = window.location.hostname;
    let production = process.env.REACT_APP_HOSTS_PROD?.split(",");
    let dev = process.env.REACT_APP_HOSTS_DEV?.split(",");
    if (production && production.indexOf(hostname) > -1) {
        state.jsonURL = process.env.REACT_APP_JSON_URL_PROD ?? null;
    } else if (dev && dev.indexOf(hostname) > -1) {
        state.jsonURL = process.env.REACT_APP_JSON_URL_DEV ?? null;
    } else {
        state.jsonURL = process.env.REACT_APP_JSON_URL_LOCAL ?? "/career.json";
    }
    state.contactURL = process.env.REACT_APP_CONTACT_URL ?? null;
    state.recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY ?? null;
    actions.loadVitals();
    effects.router.initialize({
        "/": actions.showHome,
        "/contact": actions.showContact,
        "/download": actions.showDownload,
        "/:eventType/:orgKey": actions.showEvents,
        "/:eventType/:orgKey/:eventKey": actions.showEvents,
    });
    state.initialized = true;
};

export const loadVitals = (context: Context) => {
    let { effects, state } = context;
    if (state.jsonURL) {
        effects.api.getVitals(state.jsonURL).then((vitals) => {
            if (vitals) {
                Object.values(vitals.events).forEach((event) => {
                    event.startDate = new Date(event.startDate);
                    if (event.endDate) {
                        event.endDate = new Date(event.endDate);
                        event.duration = calculateDuration(event.startDate, event.endDate);
                    } else {
                        let endDate = new Date(Date.now());
                        event.duration = calculateDuration(event.startDate, endDate);
                    }
                });
                vitals.events = vitals.events.sort(sortEvents);
                state.vitals = vitals;
            }
        });
    }
};

export const showHome = (context: Context) => {
    let { state } = context;
    state.currentPage = "home";
};

export const showContact = (context: Context) => {
    let { state } = context;
    state.currentPage = "contact";
};

export const showDownload = (context: Context) => {
    let { state } = context;
    state.currentPage = "download";
};

export const showEvents = (context: Context, params: any) => {
    let { actions, state } = context;
    actions.setEventFilters({
        eventKey: params.eventKey,
        eventType: params.eventType,
        orgKey: params.orgKey,
    });
    state.currentPage = "event";
};

export const navigate = (context: Context, href: string) => {
    let { actions, effects } = context;
    actions.setEventFilters(null);
    effects.router.open(href);
};

export const setEventFilters = (context: Context, filter: IFilter | null) => {
    let { state } = context;
    state.eventFilter = filter;
    window.scrollTo({ top: 0, behavior: "auto" });
};

export const setContactRecaptcha = (context: Context, recaptcha: string | null) => {
    let { state } = context;
    state.contactRecaptcha = recaptcha;
};

type Message = {
    name: string;
    email: string;
    phone: string | null;
    reply_by: string;
    subject: string;
    message: string;
    captcha: string | null;
};

export const sendMessage = async (context: Context, message: Message) => {
    let { actions, effects, state } = context;
    if (state.contactRecaptcha && state.contactURL) {
        actions.toggleSending();
        message.captcha = state.contactRecaptcha;
        await effects.api
            .sendMessage(message, state.contactURL)
            .then((success) => {
                if (success) {
                    state.contactSuccess = true;
                }
            })
            .catch(() => {
                state.contactSuccess = false;
            });
        actions.toggleSending();
        state.currentPage = "contactResult";
    }
};

export const toggleSending = (context: Context) => {
    let { state } = context;
    state.contactSubmitting = !state.contactSubmitting;
};

export const toggleTimelineDrawer = (context: Context) => {
    let { state } = context;
    state.timelineDrawerOpen = !state.timelineDrawerOpen;
};
