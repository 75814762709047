import * as React from "react";
import "./styles.scss";
import * as WebFont from "webfontloader";
import {
    Box,
    Button,
    Container,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    Heading,
    Icon,
    IconButton,
    Spinner,
    Text,
    useMultiStyleConfig,
} from "@chakra-ui/react";
import { useActions, useAppState } from "../../overmind";
import EventDetail from "../EventDetail";
import Footer from "../Footer";
import Header from "../Header";
import Download from "../Download";
import Contact from "../Contact";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { BiError } from "react-icons/bi";
import Home from "../Home";
import Timeline from "../Timeline";
import ContactResult from "../Contact/ContactResult";

function App(): JSX.Element {
    const state = useAppState();
    const actions = useActions();
    const styles = useMultiStyleConfig("appTheme", {});
    const fontFamilies = [
        "Lato:regular,italic,bold,bolditalic",
        "Oswald:regular,italic,bold,bolditalic",
        "Inconsolata",
    ];

    React.useEffect(() => {
        WebFont.load({
            google: {
                families: fontFamilies,
            },
        });
    });

    const renderContent = () => {
        switch (state.currentPage) {
            case "home":
                return <Home/>;
            case "contact":
                return <Contact/>;
            case "contactResult":
                return <ContactResult/>;
            case "download":
                return <Download/>;
            case "event":
                return <EventDetail/>;
            default:
                return <Home/>;
        }
    };

    if (!state.vitals) {
        return (
            <Box className={"initializing"} id={"initializing"}>
                <Container maxW={"container.xl"} minH={"100vh"} display={"flex"}>
                    <Box m={"auto"} textAlign={"center"}>
                        {state.jsonURL && state.jsonURL.length > 0 ? (
                            <Box color={"teal.800"}>
                                <Heading size={"md"} my={4}>
                                    Please Wait
                                </Heading>
                                <Spinner size={"xl"} thickness={"10px"} speed={"1s"} />
                            </Box>
                        ) : (
                            <Box color={"red.800"}>
                                <Icon as={BiError} h={24} w={24} />
                                <Heading size={"md"} my={4}>
                                    Something is Wrong
                                </Heading>
                                <Text>
                                    Sorry, there appears to be a configuration error. <br />
                                    Please try again later.
                                </Text>
                            </Box>
                        )}
                    </Box>
                </Container>
            </Box>
        );
    }

    return (
        <>
            <Box className="app" id="app" __css={styles.app}>
                <Header />
                <Container
                    id={"contentContainer"}
                    maxW={"container.xl"}
                    display={"flex"}
                    flexDirection={"column"}
                    minH={"80vh"}
                    boxShadow={"0 0 5px 5px rgba(0, 0, 0, 0.2)"}
                    bg={"white"}
                >
                    {renderContent()}
                </Container>

                <Box id={"footer"} __css={styles.footer}>
                    <Footer />
                </Box>
            </Box>
            <Drawer
                isOpen={state.timelineDrawerOpen}
                placement="right"
                onClose={() => actions.toggleTimelineDrawer()}
                size={"lg"}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader>
                        <Flex
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                        >
                            <Heading size={"lg"}>Professional History</Heading>
                            <Button
                                as={IconButton}
                                icon={<Icon as={AiOutlineDoubleRight} />}
                                onClick={() => actions.toggleTimelineDrawer()}
                            >
                                Close
                            </Button>
                        </Flex>
                    </DrawerHeader>

                    <DrawerBody>
                        <Timeline />
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
}

export default App;
