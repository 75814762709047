const baseStyleBadge = {
    color: "white",
    fontSize: ["md", "lg"],
    fontFamily: "Oswald, sans-serif",
    textTransform: "lowercase",
    pl: "10px",
    pt: "5px",
    pb: "7px",
    lineHeight: 1.1,
    maxW: "max-content",
    display: "flex",
};

const baseStyleTitle = {
    borderBottomWidth: "3px",
    borderBottomStyle: "solid",
    borderBottomColor: "gray.500",
    borderBottomRadius: "sm",
    paddingBottom: "3px",
};

const baseStyleEventDetail = {
    border: "1px solid black",
    borderColor: "gray.300",
    borderRadius: "base",
    boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
    fontSize: ["sm", "md"],
    mt: 2,
    mb: [10, null, null, 0],
    mr: [0, 0, 0, 10],
    minH: ["85vh", "75vh", "70vh", null],
    maxH: ["85vh", "75vh", "70vh", null],
    display: "flex",
    flexDirection: "column",
    // padding: 3,
};

const baseStyleFooter = {
    mt: "auto",
    padding: 2,
    bg: "gray.600",
};

const baseStyleHeader = {
    bg: "gray.600",
    color: "white",
    display: "flex",
    flexDirection: "row",
    mb: 2,
};

const variantEducation = {
    badge: {
        bg: "event.education",
    },
    title: {
        borderColor: "event.education",
    },
    eventDetail: {
        borderTopColor: "event.education",
        borderTopWidth: 2,
    },
    header: {
        bg: "event.education",
    },
    footer: {
        bg: "event.education",
    },
};

const variantVolunteer = {
    badge: {
        bg: "event.volunteer",
    },
    title: {
        borderColor: "event.volunteer",
    },
    eventDetail: {
        borderTopColor: "event.volunteer",
        borderTopWidth: 2,
    },
    header: {
        bg: "event.volunteer",
    },
    footer: {
        bg: "event.volunteer",
    },
};

const variantWork = {
    badge: {
        bg: "event.work",
    },
    title: {
        borderColor: "event.work",
    },
    eventDetail: {
        borderTopColor: "event.work",
        borderTopWidth: 2,
    },
    header: {
        bg: "event.work",
    },
    footer: {
        bg: "event.work",
    },
};

const theme = {
    parts: ["badge", "title", "header", "footer"],
    baseStyle: {
        badge: baseStyleBadge,
        title: baseStyleTitle,
        eventDetail: baseStyleEventDetail,
        header: baseStyleHeader,
        footer: baseStyleFooter,
    },
    variants: {
        education: variantEducation,
        volunteer: variantVolunteer,
        work: variantWork,
    },
    defaultProps: {},
};

export default theme;
