const baseHomeBox = {
    display: "flex",
    flexDirection: ["column", null, null, "row"],
    pt: 5,
    pb: 10,
    minH: "70vh",
};

const baseHomeSummaryBox = {
    width: ["100%", null, null, "50%"],
};

const baseHomeTimelineBox = {
    width: ["100%", null, null, "50%"],
};

export const theme = {
    parts: ["homeBox", "summaryColumn", "timelineColumn"],
    baseStyle: {
        homeBox: baseHomeBox,
        homeSummaryBox: baseHomeSummaryBox,
        homeTimelineBox: baseHomeTimelineBox,
    },
    variants: {},
    defaultProps: {},
};

export default theme;
