import * as React from "react";
import { Box, Heading, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import DonutChart from "./DonutChart";
import FrameworksBarChart from "./FrameworksBarChart";

export default function Charts(): JSX.Element {
    const tabProps = { _focus: { outline: "none", borderBottomWidth: "4px" } };
    return (
        <Box>
            <Tabs>
                <TabList>
                    <Tab {...tabProps}>
                        <Heading size={"xs"}>Tech</Heading>
                    </Tab>
                    <Tab {...tabProps}>
                        <Heading size={"xs"}>Skills</Heading>
                    </Tab>
                    <Tab {...tabProps}>
                        <Heading size={"xs"}>Verticals</Heading>
                    </Tab>
                    <Tab {...tabProps}>
                        <Heading size={"xs"}>Enterprises</Heading>
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <FrameworksBarChart />
                    </TabPanel>
                    <TabPanel>
                        <DonutChart name={"skills"} label={"skills"} />
                    </TabPanel>
                    <TabPanel>
                        <DonutChart name={"verticals"} label={"verticals"} />
                    </TabPanel>
                    <TabPanel>
                        <DonutChart name={"enterprises"} label={"enterprises"} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
}
