import * as React from "react";
import { useAppState } from "../../overmind";
import { Container, Heading, Link, Text } from "@chakra-ui/react";

export default function ContactResult(): JSX.Element {
    const state = useAppState();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    });

    if (state.contactSuccess) {
        return (
            <Container maxW={"container.lg"} py={8}>
                <Heading>Thank You!</Heading>
                <Text py={4}>Thank you for your message; I will be in touch soon!</Text>
            </Container>
        );
    }
    return (
        <Container maxW={"container.lg"} py={8}>
            <Heading>Oops, that's embarrassing!</Heading>
            <Text py={4}>
                Something appears to have gone wrong. Please try contacting me via{" "}
                <Link
                    href={`https://linkedin.com/in/${state.vitals?.identity.linkedin}`}
                    target={"_blank"}
                >
                    LinkedIn
                </Link>
                {", "}
                and let me know that this failed. Sorry for the inconvenience!
            </Text>
        </Container>
    );
}
