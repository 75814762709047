import * as React from "react";
import { Box, Link, Text, useMultiStyleConfig } from "@chakra-ui/react";
import { useActions, useAppState } from "../../overmind";
import Highlights from "./Highlights";
import Charts from "./Charts";

export default function Summary(): JSX.Element {
    const state = useAppState();
    const actions = useActions();
    const styles = useMultiStyleConfig("summaryTheme", {});
    return (
        <Box __css={styles.container} id={"summaryContainer"} className={"summaryContainer"}>
            <Box className={"mainSummary"} id={"mainSummary"} __css={styles.quote}>
                <Text>{state.vitals?.identity.summary}</Text>
                {state.contactFormEnabled ? (
                    <Text mt={5}>
                        Please <Link onClick={() => actions.navigate("/contact")}>reach out</Link>{" "}
                        to learn more about building something great together.
                    </Text>
                ) : (
                    <Text mt={5}>
                        Please{" "}
                        <Link href={state.vitals?.identity.contactUrl ?? "#"}>reach out</Link> to
                        learn more about building something great together.
                    </Text>
                )}
            </Box>

            <Highlights />

            <Box py={5} px={[0, 0, 0, 0, 5]}>
                <Charts />
            </Box>
        </Box>
    );
}
