import { derived } from "overmind";

type Identity = {
    name: string;
    label: string;
    email: string;
    contactUrl: string;
    linkedin: string;
    github: string;
    gitlab: string;
    url: string;
    summary: string;
    location: string;
    region: string;
    distributed: boolean;
};

export type Organization = {
    name: string;
    key: string;
    url: string;
    location: string;
};

export interface IEvent {
    organization: string;
    eventType: string;
    key: string;
    title: string;
    startDate: Date;
    endDate: Date;
    duration?: string;
    summary: string;
    highlights: string[];
}

export type Stat = {
    name: string;
    value: number;
    unit: string;
};

export type Skill = {
    name: string;
    value: number;
};

export type Vertical = {
    name: string;
    value: number;
};

export type Enterprise = {
    name: string;
    value: number;
};

export type Tech = {
    name: string;
    value: number;
};

export type Certifications = {
    name: string;
    organization: string;
    badge: string;
    url: string;
};

export type Vitals = {
    certifications: Certifications[];
    downloadUrl: string;
    enterprises: Enterprise[];
    events: IEvent[];
    identity: Identity;
    organizations: Organization[];
    skills: Skill[];
    stats: Stat[];
    strengths: string[];
    technologies: Tech[];
    verticals: Vertical[];
};

export interface IFilter {
    eventKey: string | null;
    eventType: string | null;
    orgKey: string | null;
}

export type State = {
    contactFormEnabled: boolean;
    contactRecaptcha: string | null;
    contactSubmitting: boolean;
    contactSuccess: boolean;
    contactURL: string | null;
    currentPage: string;
    eventFilter: IFilter | null;
    initialized: boolean;
    jsonURL: string | null;
    recaptchaKey: string | null;
    timelineDrawerOpen: boolean;
    vitals: Vitals | null;
};

export const state: State = {
    contactFormEnabled: derived((state: State): boolean => {
        return !!(state.contactURL && state.recaptchaKey);
    }),
    contactRecaptcha: null,
    contactURL: null,
    contactSubmitting: false,
    contactSuccess: false,
    currentPage: "home",
    eventFilter: null,
    initialized: false,
    jsonURL: null,
    recaptchaKey: null,
    timelineDrawerOpen: false,
    vitals: null,
};
