import { useActions, useAppState } from "../../overmind";
import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    Icon,
    IconButton,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useMultiStyleConfig,
} from "@chakra-ui/react";
import * as React from "react";
import { ExternalLinkIcon, HamburgerIcon } from "@chakra-ui/icons";
import { AiOutlineDoubleLeft, AiOutlineDownload } from "react-icons/ai";
import { BiHomeAlt, BiMailSend } from "react-icons/bi";
import { Organization } from "../../overmind/state";

export default function Header(): JSX.Element {
    const state = useAppState();
    const actions = useActions();
    const org = state.vitals?.organizations.find((org) => org.key === state.eventFilter?.orgKey);
    return (
        <Box id={"header"} mt={0} minH={"5vh"} position={"sticky"} top={"0px"} zIndex={100}>
            <Box
                bg={"teal.900"}
                color={"white"}
                py={"1.5vmin"}
                boxShadow={"0 1px 10px 5px rgba(0, 0, 0, 0.2)"}
            >
                <Container maxW={"container.xl"} display={"flex"} flexDirection={"row"}>
                    <Box onClick={() => actions.navigate("/")} _hover={{ cursor: "pointer" }}>
                        <Heading as={"h1"}>{state.vitals?.identity.name}</Heading>
                        <Text fontSize={["sm", "md", "lg"]} fontWeight={"bold"}>
                            {state.vitals?.identity.label}
                        </Text>
                    </Box>
                    <Box ml={"auto"} mt={"auto"} mb={"auto"}>
                        <Menu placement={"bottom-end"}>
                            <MenuButton
                                as={IconButton}
                                aria-label="Options"
                                icon={<HamburgerIcon h={8} w={8} />}
                                variant="outline"
                                borderRadius={"base"}
                                borderColor={"gray.700"}
                                boxShadow={"0 0 4px 5px rgba(0, 0, 0, 0.2)"}
                                _hover={{ bg: "gray.700" }}
                                _expanded={{ bg: "gray.700" }}
                                _focus={{ boxShadow: "outline", outline: "none" }}
                            />
                            <MenuList zIndex={"100"} color={"black"}>
                                <MenuItem
                                    icon={<Icon as={BiHomeAlt} />}
                                    onClick={() => actions.navigate("/")}
                                >
                                    Home
                                </MenuItem>
                                {state.contactFormEnabled ? (
                                    <MenuItem
                                        icon={<Icon as={BiMailSend} />}
                                        onClick={() => actions.navigate("/contact")}
                                    >
                                        Contact
                                    </MenuItem>
                                ) : (
                                    <MenuItem
                                        icon={<Icon as={BiMailSend} />}
                                        as={"a"}
                                        href={state.vitals?.identity.contactUrl}
                                    >
                                        Contact
                                    </MenuItem>
                                )}
                                <MenuItem
                                    icon={<Icon as={AiOutlineDownload} />}
                                    onClick={() => actions.navigate("/download")}
                                >
                                    Download
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </Box>
                </Container>
            </Box>
            {org && <OrganizationHeader {...org} />}
        </Box>
    );
}

function OrganizationHeader(org: Organization): JSX.Element {
    const actions = useActions();
    const state = useAppState();
    const eventType = state.eventFilter?.eventType ?? "default";
    const styles = useMultiStyleConfig("headerTheme", { variant: eventType });

    return (
        <Box bg={"yellow.50"}>
            <Box __css={styles.container}>
                <Flex flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Box __css={styles.badge}>{eventType}</Box>
                    <Button
                        __css={styles.button}
                        size={"xs"}
                        as={IconButton}
                        icon={<Icon as={AiOutlineDoubleLeft} />}
                        onClick={() => actions.toggleTimelineDrawer()}
                        aria-label={"open timeline"}
                    />
                </Flex>
                <Flex flexDirection={"row"} justifyContent={"space-between"}>
                    <Heading size={"md"}>
                        {org.url ? (
                            <Link href={org.url} target={"_blank"}>
                                {org.name}
                                <ExternalLinkIcon mx={1} pb={1} />
                            </Link>
                        ) : (
                            org.name
                        )}
                    </Heading>
                </Flex>
            </Box>
        </Box>
    );
}
