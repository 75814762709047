import * as React from "react";
import { Box, Button, Container, Heading, Link, Text } from "@chakra-ui/react";
import { useActions, useAppState } from "../../overmind";
import ReCAPTCHA from "react-google-recaptcha";
import { Formik } from "formik";
import { InputControl, TextareaControl } from "formik-chakra-ui";
import * as Yup from "yup";
import { ExternalLinkIcon } from "@chakra-ui/icons";

export default function Contact(): JSX.Element {
    const state = useAppState();
    const actions = useActions();

    const recaptchaKey = state.recaptchaKey ?? "";
    const recaptchaSet = state.contactRecaptcha ? state.contactRecaptcha.length > 1 : false;

    const initialValues = {
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
    };

    // sadly ECMAScript 2015 is missing \p{L} escape sequence
    const nameSubjectRegex = new RegExp(
        /^[0-9a-zA-ZáàâäãåçéèêëíìîïñóòôöõøßúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕØẞÚÙÛÜÝŸÆŒ .\-',]*$/
    );

    const messageRegex = new RegExp(
        /^[0-9a-zA-ZáàâäãåçéèêëíìîïñóòôöõøßúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕØẞÚÙÛÜÝŸÆŒ .,\-'"!@#$%&()+:;?/]*$/
    );

    const validationSchema = Yup.object({
        name: Yup.string()
            .matches(nameSubjectRegex, { message: "invalid character(s)" })
            .min(2)
            .max(254)
            .required(),
        email: Yup.string().min(2).max(320).email().required(),
        phone: Yup.string()
            .matches(/^[0-9]*$/, { message: "digits only, please" })
            .min(7)
            .max(35),
        subject: Yup.string()
            .matches(nameSubjectRegex, { message: "invalid character(s)" })
            .min(2)
            .max(254)
            .required(),
        message: Yup.string()
            .matches(messageRegex, { message: "invalid character(s)" })
            .min(1)
            .max(10240)
            .required(),
    });

    const onSubmit = (values: any) => {
        actions.sendMessage(values);
    };


    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container maxW={"container.lg"} py={8}>
            <Heading>Let's Connect</Heading>
            <Text py={4}>
                Please complete the form below to contact me, or feel to reach out via{" "}
                <Link
                    href={`https://linkedin.com/in/${state.vitals?.identity.linkedin}`}
                    target={"_blank"}
                >
                    LinkedIn <ExternalLinkIcon pb={"3px"} />
                </Link>
                .
            </Text>
            <Box pt={5}>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                >
                    {({ handleSubmit }) => (
                        <Box as={"form"} onSubmit={handleSubmit as any} id={"contactForm"}>
                            <InputControl name={"name"} label={"Name"} isRequired mb={2} />
                            <InputControl name={"email"} label={"E-mail"} isRequired mb={2} />
                            <InputControl name={"phone"} label={"Phone"} mb={2} />
                            <InputControl name={"subject"} label={"Subject"} isRequired mb={2} />
                            <TextareaControl name={"message"} label={"Message"} isRequired mb={2} />
                        </Box>
                    )}
                </Formik>
                <Box mt={5}>
                    <ReCAPTCHA
                        sitekey={recaptchaKey}
                        onChange={(value) => actions.setContactRecaptcha(value)}
                    />
                </Box>
                <Button
                    colorScheme={"blue"}
                    mr={4}
                    my={4}
                    isDisabled={
                        !(
                            recaptchaSet &&
                            state.contactURL &&
                            state.recaptchaKey &&
                            !state.contactSubmitting
                        )
                    }
                    isLoading={state.contactSubmitting}
                    loadingText={"Please wait ..."}
                    type={"submit"}
                    form={"contactForm"}
                >
                    Submit
                </Button>
            </Box>
        </Container>
    );
}
