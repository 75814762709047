import {
    Box,
    Container,
    Heading,
    Link,
    List,
    ListIcon,
    ListItem,
    useMultiStyleConfig,
} from "@chakra-ui/react";
import * as React from "react";
import { AiFillLinkedin, AiOutlineDownload } from "react-icons/ai";
import { BiBrain, BiMailSend, BiMapAlt } from "react-icons/bi";
import { BsFillPersonFill, BsLightning } from "react-icons/bs";
import { HiOutlineDocumentDuplicate, HiOutlineGlobe } from "react-icons/hi";
import { FaGithub, FaGitlab, FaReact } from "react-icons/fa";
import { MdPinDrop } from "react-icons/md";
import { useActions, useAppState } from "../../overmind";

export default function Footer(): JSX.Element {
    const state = useAppState();
    const actions = useActions();
    const listProps = { spacing: 2, pl: 1, pt: 2 };
    const styles = useMultiStyleConfig("footerTheme", {});
    return (
        <Box py={5}>
            <Container display={"flex"} maxW={"container.xl"} flexWrap={"wrap"}>
                <Box __css={styles.item}>
                    <Heading size={"md"}>Location</Heading>
                    <List {...listProps}>
                        <ListItem>
                            <ListIcon as={MdPinDrop} />
                            {state.vitals?.identity.location}
                        </ListItem>
                        <ListItem>
                            <ListIcon as={BiMapAlt} />
                            {state.vitals?.identity.region}
                        </ListItem>
                        {state.vitals?.identity.distributed && (
                            <ListItem>
                                <ListIcon as={HiOutlineGlobe} />
                                Distributed/Hybrid
                            </ListItem>
                        )}
                    </List>
                </Box>

                <Box __css={styles.item}>
                    <Heading size={"md"}>Connect</Heading>
                    <List {...listProps}>
                        <ListItem>
                            <ListIcon as={BiMailSend} />
                            {state.contactFormEnabled ? (
                                <Link onClick={() => actions.navigate("/contact")}>contact</Link>
                            ) : (
                                <Link href={state.vitals?.identity.contactUrl}>contact</Link>
                            )}
                        </ListItem>
                        <ListItem>
                            <ListIcon as={AiOutlineDownload} />
                            <Link onClick={() => actions.navigate("/download")}>download</Link>
                        </ListItem>
                        {state.vitals?.identity.linkedin && (
                            <ListItem>
                                <ListIcon as={AiFillLinkedin} />
                                <Link
                                    isExternal
                                    href={`https://www.linkedin.com/in/${state.vitals.identity.linkedin}/`}
                                >
                                    {state.vitals.identity.linkedin}
                                </Link>
                            </ListItem>
                        )}
                        {state.vitals?.identity.gitlab && (
                            <ListItem>
                                <ListIcon as={FaGitlab} />
                                <Link
                                    isExternal
                                    href={`https://gitlab.com/${state.vitals.identity.gitlab}`}
                                >
                                    {state.vitals.identity.gitlab}
                                </Link>
                            </ListItem>
                        )}
                        {state.vitals?.identity.github && (
                            <ListItem>
                                <ListIcon as={FaGithub} />
                                <Link
                                    isExternal
                                    href={`https://github.com/${state.vitals.identity.github}`}
                                >
                                    {state.vitals.identity.github}
                                </Link>
                            </ListItem>
                        )}
                    </List>
                </Box>

                <Box __css={styles.item}>
                    <Heading size={"md"}>Credits</Heading>
                    <List {...listProps}>
                        <ListItem>
                            <ListIcon as={FaReact} />
                            <Link isExternal href={"https://reactjs.org"}>
                                react
                            </Link>
                        </ListItem>
                        <ListItem>
                            <ListIcon as={BiBrain} />
                            <Link isExternal href={"https://overmindjs.org"}>
                                overmind
                            </Link>
                        </ListItem>
                        <ListItem>
                            <ListIcon as={BsLightning} />
                            <Link isExternal href={"https://chakra-ui.com"}>
                                chakra-ui
                            </Link>
                        </ListItem>
                        <ListItem>
                            <ListIcon as={HiOutlineDocumentDuplicate} />
                            <Link isExternal href={"https://pandoc.org"}>
                                pandoc
                            </Link>
                        </ListItem>
                        <ListItem>
                            <ListIcon as={BsFillPersonFill} />
                            <Link
                                isExternal
                                href={
                                    "https://www.freecodecamp.org/news/how-to-create-a-timeline-component-with-react-1b216f23d3d4/"
                                }
                            >
                                Florin Pop
                            </Link>
                        </ListItem>
                    </List>
                </Box>

                <Box __css={styles.item}>
                    <Heading size={"md"}>Code</Heading>
                    <List {...listProps}>
                        <ListItem>
                            <ListIcon as={FaGitlab} />
                            <Link isExternal href={"https://gitlab.com/wryfi/vita"}>
                                vita (this project)
                            </Link>
                        </ListItem>
                    </List>
                </Box>
            </Container>
        </Box>
    );
}
