import * as React from "react";
import { Box } from "@chakra-ui/react";
import { useAppState } from "../../overmind";
import theme from "../../chakra/theme";
import { Chart as ChartJS, BarElement, ChartData, ChartOptions, LinearScale } from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(LinearScale, BarElement);

export default function FrameworksBarChart(): JSX.Element {
    const state = useAppState();
    if (state.vitals?.technologies) {
        let chartData = state.vitals.technologies;
        let labels: string[] = [];
        let values: number[] = [];
        Object.values(chartData).forEach((data) => {
            labels.push(data.name);
            values.push(data.value);
        });
        let data: ChartData<"bar"> = {
            labels: labels,
            datasets: [
                {
                    label: "technologies",
                    data: values,
                    borderWidth: 5,
                    backgroundColor: theme.colors.yellow[400],
                    borderColor: theme.colors.yellow[400],
                },
            ],
        };
        let options: ChartOptions<"bar"> = {
            indexAxis: "y",
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                },
            },
            maintainAspectRatio: false,
            scales: {
                y: {
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        autoSkip: false,
                    },
                    position: "left",
                },
                x: {
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        display: false,
                    },
                    position: "bottom",
                },
            },
        };
        return (
            <Box display={"flex"} minH={"250px"}>
                <Bar data={data} options={options} height={250} />
            </Box>
        );
    }
    return <Box display={"flex"} minH={"250px"} />;
}
