import * as React from "react";
import { Box, Image, Link } from "@chakra-ui/react";
import { useAppState } from "../../overmind";

export default function Certifications(): JSX.Element {
    const state = useAppState();
    return (
        <Box>
            <Box
                display={"flex"}
                gridColumnGap={[4, null, null, 20]}
                alignItems={"center"}
                flexWrap={"wrap"}
                justifyContent={"center"}
            >
                {state.vitals &&
                    Object.values(state.vitals.certifications).map((cert, idx) => {
                        return (
                            <Box maxW={"150px"} key={idx} pb={[4, null, null, 0]}>
                                <Link href={cert.url} target={"_blank"}>
                                    <Image src={cert.badge} maxW={["150px", null, null, "200px"]} />
                                </Link>
                            </Box>
                        );
                    })}
            </Box>
        </Box>
    );
}
