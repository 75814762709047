import * as React from "react";
import {
    Box,
    Heading,
    Text,
    UnorderedList,
    ListItem,
    useMultiStyleConfig,
    Container,
} from "@chakra-ui/react";
import { useAppState } from "../../overmind";
import { IEvent, State } from "../../overmind/state";

export default function EventDetail(): JSX.Element {
    const state = useAppState();
    const events = getEvents(state);
    const org = state.vitals?.organizations.find((org) => org.key === state.eventFilter?.orgKey);
    const styles = useMultiStyleConfig("eventDetailTheme", {
        variant: state.eventFilter?.eventType,
    });

    React.useEffect(() => {
        if (state.eventFilter) {
            let detailElement = document.getElementById(
                `detail-heading-${state.eventFilter.eventKey}`
            );
            let headerElement = document.getElementById("header");
            if (detailElement && headerElement) {
                window.scrollTo(0, detailElement.offsetTop - headerElement.offsetHeight - 30);
            }
        }
    });

    if (events.length > 0 && org) {
        return (
            <Container
                py={8}
                maxW={"container.lg"}
                display={"flex"}
                flexDirection={"column"}
                top={0}
                minH={"80vh"}
            >
                <Box id={"events"}>
                    {Object.values(events).map((event, idx) => {
                        const eventEnd: string = event?.endDate
                            ? event.endDate.toLocaleDateString()
                            : "present";
                        return (
                            <Box key={idx} id={`detail-${event.key}`} py={"1.5vmin"}>
                                <Box className={"eventTitle"} __css={styles.title}>
                                    <Heading
                                        as={"h3"}
                                        size={"md"}
                                        id={`detail-heading-${event.key}`}
                                    >
                                        {event.title}
                                    </Heading>
                                </Box>

                                <Box mt={1} fontSize={["xs", "sm"]} className={"eventDate"}>
                                    <Text>
                                        {event?.startDate.toLocaleDateString()} &#8212; {eventEnd} (
                                        {" ~ "}
                                        {event?.duration})
                                    </Text>
                                </Box>

                                <Box py={3}>
                                    <Text as={"i"} fontSize={"lg"} my={5}>
                                        {event.summary && event.highlights ? (
                                            <>
                                                As {event.title}, I {event.summary}. I also …
                                            </>
                                        ) : event.summary && !event.highlights ? (
                                            <>
                                                As {event.title}, I {event.summary}.
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </Text>
                                </Box>

                                <UnorderedList ml={8}>
                                    {event.highlights &&
                                        Object.values(event.highlights).map((highlight, idx) => {
                                            return <ListItem key={idx}>{highlight}</ListItem>;
                                        })}
                                </UnorderedList>
                            </Box>
                        );
                    })}
                </Box>
            </Container>
        );
    }
    return (
        <Box mx={[5, null, 20]} py={8}>
            <Heading>Oops, data not found!</Heading>
        </Box>
    );
}

function getEvents(state: State): IEvent[] {
    const org = state.vitals?.organizations.find((org) => org.key === state.eventFilter?.orgKey);
    let events = state.vitals?.events?.filter((event) => event.organization === org?.key);
    events = events?.filter((event) => event.eventType === state.eventFilter?.eventType);
    return events ?? [];
}
