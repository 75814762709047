const baseStyleItem = {
    mr: "auto",
    ml: "auto",
    width: ["100%", "50%", null, "25%"],
    mb: 10,
};

export const theme = {
    parts: ["item"],
    baseStyle: {
        item: baseStyleItem,
    },
    variants: {},
    defaultProps: {},
};

export default theme;
