import { extendTheme } from "@chakra-ui/react";
import { theme as defaultTheme } from "@chakra-ui/react";
import appTheme from "../components/App/theme";
import eventDetailTheme from "../components/EventDetail/theme";
import footerTheme from "../components/Footer/theme";
import headerTheme from "../components/Header/theme";
import homeTheme from "../components/Home/theme";
import summaryTheme from "../components/Summary/theme";
import timelineTheme from "../components/Timeline/theme";

const theme = extendTheme({
    fonts: {
        heading: `Oswald, serif`,
        body: `Lato, sans-serif`,
        mono: `Inconsolata, monospace`,
    },
    colors: {
        event: {
            education: defaultTheme.colors.red[600],
            volunteer: defaultTheme.colors.green[600],
            work: defaultTheme.colors.blue[600],
        },
    },
    styles: {
        global: {
            body: {
                bg: "yellow.50",
            },
        },
    },
    components: {
        appTheme,
        eventDetailTheme,
        footerTheme,
        headerTheme,
        homeTheme,
        summaryTheme,
        timelineTheme,
    },
});

export default theme;
