const baseStyleBadge = {
    position: "absolute",
    top: 0,
    left: 0,
    color: "white",
    fontSize: ["xs", "sm", null, "md"],
    fontFamily: "Oswald,sans-serif",
    textTransform: "lowercase",
    px: "3px",
    pb: "3px",
    lineHeight: 1,
    borderRadius: "sm",
    boxShadow: "inset 1px 1px 1px rgba(0, 0, 0, 0.2)",
    minW: ["100%", 0, null],
    textAlign: ["center", null],
};

const baseStyleEvent = {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    background: "white",
    position: "relative",
    gap: 0,
    my: 0,
    mx: 0,
    padding: 2,
    borderRadius: "base",
    borderTopWidth: "2px",
    borderTopColor: "gray.500",
    borderLeftWidth: "1px",
    borderLeftColor: "gray.200",
    boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
    width: "calc(50% - 25px)",
    zIndex: 1,
    _odd: {
        padding: 2,
        display: "flex",
        alignSelf: "flex-end",
        justifyContent: "flex-start",
    },
    _hover: {
        bg: "blue.50",
        boxShadow: "10px 10px 10px rgba(59, 130, 196, 0.2)",
        cursor: "pointer",
    },
    _selected: {
        bg: "orange.50",
    },
    _focus: {
        boxShadow: "10px 10px 10px rgba(59, 130, 196, 0.2)",
        outline: "none",
    },
    _after: {
        content: '" "',
        boxShadow: "1px -1px 1px rgba(0, 0, 0, 0.2)",
        position: "absolute",
        right: "-7.5px",
        top: "calc(50% - 7.5px)",
        transform: "rotate(45deg)",
        width: "15px",
        height: "15px",
        zIndex: -10,
        bg: "inherit",
    },
};

const baseStyleEventBody = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    position: "relative",
    textAlign: ["center", "right"],
    px: [2, 5, null],
    zIndex: 1,
    bg: "inherit",
};

const baseStyleCircle = {
    backgroundColor: "inherit",
    borderWidth: "3px",
    borderStyle: "solid",
    borderColor: "red.500",
    borderRadius: "50%",
    position: "absolute",
    top: "calc(50% - 10px)",
    right: "-43px",
    width: "20px",
    height: "20px",
    zIndex: "100",
};

const baseStyleTimeline = {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    my: 5,
    _after: {
        backgroundColor: "red.500",
        content: '""',
        position: "absolute",
        left: "calc(50% - 2px)",
        width: 1,
        height: "100%",
    },
};

const variantWork = {
    badge: {
        bg: "event.work",
    },
    event: {
        borderTopColor: "event.work",
    },
};

const variantVolunteer = {
    badge: {
        bg: "event.volunteer",
    },
    event: {
        borderTopColor: "event.volunteer",
    },
};

const variantEducation = {
    badge: {
        bg: "event.education",
    },
    event: {
        borderTopColor: "event.education",
    },
};

const theme = {
    parts: ["badge", "circle", "event", "eventBody", "timeline"],
    baseStyle: {
        badge: baseStyleBadge,
        circle: baseStyleCircle,
        event: baseStyleEvent,
        eventBody: baseStyleEventBody,
        timeline: baseStyleTimeline,
    },
    variants: {
        work: variantWork,
        volunteer: variantVolunteer,
        education: variantEducation,
    },
    defaultProps: {
        badge: {
            variant: "work",
        },
        event: {
            variant: "work",
        },
        eventBody: {
            variant: "work",
        },
    },
};

export default theme;
