import * as React from "react";
import { Box, Flex, Heading, Text, useMultiStyleConfig } from "@chakra-ui/react";
import { IEvent } from "../../overmind/state";
import { useActions, useAppState } from "../../overmind";
import "./styles.scss";

function scrollToEvent(timelineEvent: HTMLElement) {
    let eventPosition: DOMRect = timelineEvent.getBoundingClientRect();
    let timeline: Element = timelineEvent.parentNode as Element;
    let timelinePosition: DOMRect = timeline.getBoundingClientRect();
    let top = eventPosition.top - timelinePosition.top + timeline.scrollTop;
    (timeline.parentNode as Element).scrollTo({ top: top - 50 });
}

export default function Timeline(): JSX.Element {
    const state = useAppState();
    const events: IEvent[] = state.vitals?.events ?? [];
    const styles = useMultiStyleConfig("timelineTheme", {});

    // Scrolls to the correct timeline position on component load
    React.useEffect(() => {
        if (state.eventFilter) {
            let timelineEvent = document.getElementById(`event-${state.eventFilter.eventKey}`);
            if (timelineEvent) {
                scrollToEvent(timelineEvent);
            }
        }
    });

    return (
        <Box px={5} id={"timelineWrapper"} maxH={"90vh"} overflow={"auto"}>
            <Box __css={styles.timeline} className={"timeline"} id={"timeline"} mb={"5vh"}>
                {Object.values(events).map((event, idx) => {
                    return (
                        <Event
                            event={event}
                            variant={event.eventType}
                            key={`timeline-${event.key}`}
                            index={idx}
                        />
                    );
                })}
            </Box>
        </Box>
    );
}

type eventProps = {
    event: IEvent;
    variant: string;
    index: number;
};

function Event({ event, variant }: eventProps): JSX.Element {
    const state = useAppState();
    const actions = useActions();
    const org = state.vitals?.organizations.find((org) => org.key === event.organization);
    const styles = useMultiStyleConfig("timelineTheme", { variant: variant });
    const eventEnd: string = event.endDate ? event.endDate.toLocaleDateString() : "present";

    React.useEffect(() => {
        let key = state.eventFilter?.eventKey;
        let element = document.getElementById(`event-${key}`);
        element && element.setAttribute("aria-selected", "true");
    });

    const handleKeyPress = (kbdEvent: React.KeyboardEvent<HTMLDivElement>, event: IEvent) => {
        if (kbdEvent.key === "Enter") {
            actions.navigate(`/${event.eventType}/${event.organization}/${event.key}`);
            if (state.timelineDrawerOpen) {
                actions.toggleTimelineDrawer();
            }
        }
    };
    const handleClick = (event: IEvent) => {
        actions.navigate(`/${event.eventType}/${event.organization}/${event.key}`);
        if (state.timelineDrawerOpen) {
            actions.toggleTimelineDrawer();
        }
    };
    return (
        <Box
            __css={styles.event}
            className={"event"}
            id={`event-${event.key}`}
            onClick={() => handleClick(event)}
            onKeyPress={(kbdEvent) => handleKeyPress(kbdEvent, event)}
            tabIndex={0}
            my={2}
        >
            <Flex __css={styles.eventBody} className={"eventBody"} id={"eventBody"}>
                <Box __css={styles.badge} className={"eventBadge"}>
                    {event.eventType}
                </Box>
                <Box
                    className={"eventHeading"}
                    maxW={["100%", null, null, "70%", null]}
                    w={["100%"]}
                    pt={[5, 5, 5, 0]}
                >
                    <Heading fontSize={["sm", null, "lg"]}>{event.title}</Heading>
                </Box>
                <Box className={"orgName"} py={1} w={"100%"}>
                    <Text fontFamily={"Oswald,serif"} fontSize={["sm", null, "md"]}>
                        {org?.name}
                    </Text>
                </Box>
                <Box className={"eventDates"} w={"100%"}>
                    <Text fontSize={["xs", null, "sm"]} fontFamily={"Oswald,serif"} py={1}>
                        {event.startDate.toLocaleDateString()} &#8212; {eventEnd} <br />
                    </Text>
                    <Text fontSize={["xs", null, "sm"]} fontFamily={"Oswald,serif"}>
                        {"~ "} {event.duration}
                    </Text>
                </Box>
                <Box as="span" __css={styles.circle} className={"circle"} />
            </Flex>
        </Box>
    );
}
