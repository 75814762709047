import * as React from "react";
import { Button, Container, Flex, Heading, Icon, Text } from "@chakra-ui/react";
import { AiOutlineDownload } from "react-icons/ai";
import {
    ImFileOpenoffice,
    ImFilePdf,
    ImFileText2,
    ImFileWord,
} from "react-icons/im";
import { IconType } from "react-icons";

export default function Download(): JSX.Element {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <Container maxW={"container.lg"} pt={8}>
            <Heading>Download</Heading>
            <Text py={4}>
                For offline viewing or entry into an applicant tracking system, please choose from
                the following formats:
            </Text>
            <Flex flexDirection={"row"} flexWrap={"wrap"} justifyContent={"space-between"}>
                <DownloadIcon
                    icon={ImFilePdf}
                    href={"/file?format=pdf"}
                    color={"red.600"}
                    fileType={"PDF"}
                />
                <DownloadIcon
                    icon={ImFileWord}
                    href={"/file?format=docx"}
                    color={"blue.600"}
                    fileType={"Word"}
                />
                <DownloadIcon
                    icon={ImFileOpenoffice}
                    href={"/file?format=odt"}
                    color={"green.600"}
                    fileType={"ODT"}
                />
                <DownloadIcon
                    icon={ImFileText2}
                    href={"/file?format=txt"}
                    color={"gray.600"}
                    fileType={"TXT"}
                />
            </Flex>
        </Container>
    );
}

interface downloadIconProps {
    color: string;
    fileType: string;
    href: string;
    icon: IconType;
}

function DownloadIcon({ color, fileType, href, icon }: downloadIconProps): JSX.Element {
    return (
        <Flex flexDirection={["row", null, null, "column"]} p={6} alignItems={"center"}>
            <Icon as={icon} boxSize={"10rem"} color={color} py={3} />
            <Button
                leftIcon={<Icon as={AiOutlineDownload} />}
                bg={color}
                color={"white"}
                onClick={() => (window.location.href = href)}
            >
                {fileType}
            </Button>
        </Flex>
    );
}
