import * as React from "react";
import { Box, Heading, useMultiStyleConfig } from "@chakra-ui/react";
import Summary from "../Summary";
import Timeline from "../Timeline";

export default function Home(): JSX.Element {
    const styles = useMultiStyleConfig("homeTheme", {});

    React.useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <Box id={"home"} __css={styles.homeBox}>
            <Box id={"homeSummaryBox"} __css={styles.homeSummaryBox}>
                <Summary />
            </Box>
            <Box id={"homeTimelineBox"} __css={styles.homeTimelineBox}>
                <Heading fontSize={"2xl"} textAlign={"center"} py={4}>
                    Professional History
                </Heading>
                <Timeline />
            </Box>
        </Box>
    );
}
