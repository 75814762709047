import page from "page";
import { Vitals } from "./state";

export type UrlParams = {
    [param: string]: string;
} | void;

export const router = {
    initialize(routes: { [url: string]: (params: UrlParams) => void }) {
        Object.keys(routes).forEach((url) => {
            page(url, ({ params }) => routes[url](params));
        });
        page.start();
    },
    open(url: string) {
        page.show(url);
    },
};

export const api = {
    getVitals: async (url: string): Promise<Vitals> => {
        const response = await fetch(url);
        let responseJson: Vitals = await response.json();
        return responseJson as Vitals;
    },
    sendMessage: async (message: Object, url: string): Promise<boolean> => {
        const response = await fetch(url, {
            method: "POST",
            headers: { "content-type": "application/json;charset=UTF-8" },
            body: JSON.stringify(message),
        });
        console.log(response.status);
        return response.status === 200 || response.status === 201 || response.status === 202;
    },
};
