import * as React from "react";
import {
    Box,
    Heading,
    ListItem,
    Tab,
    Table,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tbody,
    Td,
    Tr,
    UnorderedList,
} from "@chakra-ui/react";
import { useAppState } from "../../overmind";
import Certifications from "../Certifications";

export default function Highlights(): JSX.Element {
    const tabProps = { _focus: { outline: "none" }, borderBottomWidth: "4px" };
    const state = useAppState();
    return (
        <Box pt={7} pb={2} px={[0, 0, 0, 0, 5]}>
            <Tabs>
                <TabList>
                    <Tab {...tabProps}>
                        <Heading size={"xs"}>Strengths</Heading>
                    </Tab>
                    <Tab {...tabProps}>
                        <Heading size={"xs"}>Expertise</Heading>
                    </Tab>
                    <Tab {...tabProps}>
                        <Heading size={"xs"}>Certifications</Heading>
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <UnorderedList fontSize={["sm", null, null, "md"]}>
                            {state.vitals &&
                                Object.values(state.vitals.strengths).map((strength, idx) => {
                                    return <ListItem key={idx}>{strength}</ListItem>;
                                })}
                        </UnorderedList>
                    </TabPanel>
                    <TabPanel>
                        <Table size={"sm"}>
                            <Tbody>
                                {state.vitals &&
                                    Object.values(state.vitals.stats).map((stat, idx) => {
                                        return (
                                            <Tr key={idx}>
                                                <Td>{stat.name}</Td>
                                                <Td>{stat.value} years</Td>
                                            </Tr>
                                        );
                                    })}
                            </Tbody>
                        </Table>
                    </TabPanel>
                    <TabPanel>
                        <Certifications />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
}
