export function pluralize(count: number, noun: string, suffix: string = "s") {
    if (count > 0) {
        return `${count} ${noun}${count !== 1 ? suffix : ""}`;
    } else {
        return "";
    }
}

export function calculateDuration(start: Date, end: Date): string {
    let delta: number = end.getTime() - start.getTime();
    let totalDays = delta / 1000 / 86400;
    let totalYears = Math.floor(totalDays / 365);
    let totalMonths = Math.floor(totalDays / 28);
    if (totalYears < 1) {
        let remainder = totalDays - totalMonths * 28;
        if (remainder < 15) {
            return `${pluralize(totalMonths, "month")}`;
        } else {
            let months = totalMonths + 1;
            if (months < 12) {
                return `${pluralize(months, "month")}`;
            } else {
                return "1 year";
            }
        }
    } else {
        let monthsRemaining = Math.floor((totalDays - totalYears * 365) / 28);
        if (monthsRemaining > 0 && monthsRemaining < 7) {
            return `${pluralize(totalYears, "year")}, ${pluralize(monthsRemaining, "month")}`;
        } else if (monthsRemaining > 6) {
            return `${pluralize(totalYears + 1, "year")}`;
        } else {
            return `${pluralize(totalYears, "year")}`;
        }
    }
}

export function sortEvents(first: any, second: any) {
    if (first.startDate < second.startDate) {
        return 1;
    }
    if (first.startDate > second.startDate) {
        return -1;
    }
    return 0;
}
