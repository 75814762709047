const baseStyleContainer = {
    pt: 5,
    pb: 10,
    pr: [0, null, null, null, 10],
};

const baseStyleQuote = {
    borderLeftStyle: "solid",
    borderLeftWidth: 8,
    borderLeftColor: "teal.900",
    borderRadius: "sm",
    pl: 3,
    py: 2,
    fontStyle: "italic",
    fontSize: ["md", null, null, "lg"],
};

const baseStyleStats = {
    py: 10,
    textAlign: "center",
    pl: [5, null, null, null, 10],
    pr: [5, null, null, null, 20],
    fontFamily: "Oswald, serif",
};

export const theme = {
    parts: ["container", "quote", "stats"],
    baseStyle: {
        container: baseStyleContainer,
        quote: baseStyleQuote,
        stats: baseStyleStats,
    },
    variants: {},
    defaultProps: {},
};

export default theme;
