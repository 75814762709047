import * as React from "react";
import { Box } from "@chakra-ui/react";
import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    ChartOptions,
    Tooltip,
    Legend,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useAppState } from "../../overmind";
import { Enterprise, Skill, Vertical } from "../../overmind/state";
import theme from "../../chakra/theme";

ChartJS.register(ArcElement, CategoryScale, Tooltip, Legend);

type summaryDonutOpts = {
    name: string;
    label: string;
};

export default function DonutChart({ name, label }: summaryDonutOpts): JSX.Element {
    const state = useAppState();
    let chartData: Skill[] | Enterprise[] | Vertical[] = [];
    if (state.vitals) {
        switch (name) {
            case "enterprises":
                chartData = state.vitals.enterprises;
                break;
            case "skills":
                chartData = state.vitals.skills;
                break;
            case "verticals":
                chartData = state.vitals.verticals;
                break;
        }
        let labels: string[] = [];
        let values: number[] = [];
        Object.values(chartData).forEach((data) => {
            labels.push(data.name);
            values.push(data.value);
        });
        let data = {
            labels: labels,
            datasets: [
                {
                    label: label,
                    data: values,
                    borderWidth: 5,
                    backgroundColor: [
                        theme.colors.green[400],
                        theme.colors.yellow[400],
                        theme.colors.blue[400],
                        theme.colors.red[400],
                        theme.colors.purple[400],
                    ],
                },
            ],
        };
        let options: ChartOptions<"doughnut"> = {
            plugins: {
                legend: {
                    position: "right",
                },
                tooltip: {
                    enabled: true,
                    displayColors: false,
                },
            },
            maintainAspectRatio: false,
        };
        return (
            <Box display={"flex"} minH={"225px"}>
                <Doughnut data={data} options={options} />
            </Box>
        );
    }
    return <Box display={"flex"} minH={"225px"}></Box>;
}
