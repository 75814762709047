const baseStyleBadge = {
    color: "white",
    // fontSize: ["sm", null, null, "md"],
    fontSize: "md",
    fontFamily: "Oswald,sans-serif",
    textTransform: "lowercase",
    borderRadius: "sm",
    boxShadow: "inset 1px 1px 1px rgba(0, 0, 0, 0.2)",
    textAlign: ["center", null],
    px: "5px",
    pt: "3px",
    pb: "5px",
    lineHeight: 1.1,
    maxW: "max-content",
    display: "flex",
    bg: "teal.900",
};

const baseStyleButton = {
    bg: "teal.900",
    color: "white",
};

const baseStyleContainer = {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    color: "black",
    boxShadow: "0 0 5px 5px rgba(0, 0, 0, 0.2)",
    clipPath: "inset(-1px -20px -.35px -20px)",
    borderBottomStyle: "solid",
    borderBottomWidth: "3px",
    borderBottomColor: "teal.900",
    borderRadius: "sm",
    py: "1.75vmin",
    maxW: "container.xl",
    marginInlineStart: "auto",
    marginInlineEnd: "auto",
    paddingInlineStart: "1rem",
    paddingInlineEnd: "1rem",
};

const variantEducation = {
    badge: {
        bg: "event.education",
    },
    button: {
        bg: "event.education",
    },
    container: {
        borderColor: "event.education",
    },
};

const variantVolunteer = {
    badge: {
        bg: "event.volunteer",
    },
    button: {
        bg: "event.volunteer",
    },
    container: {
        borderColor: "event.volunteer",
    },
};

const variantWork = {
    badge: {
        bg: "event.work",
    },
    button: {
        bg: "event.work",
    },
    container: {
        borderColor: "event.work",
    },
};

const theme = {
    parts: ["badge"],
    baseStyle: {
        badge: baseStyleBadge,
        button: baseStyleButton,
        container: baseStyleContainer,
    },
    variants: {
        education: variantEducation,
        volunteer: variantVolunteer,
        work: variantWork,
    },
    defaultProps: {},
};

export default theme;
