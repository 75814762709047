import * as React from "react";
import ReactDOM from "react-dom";

import { createOvermind } from "overmind";
import { Provider as OvermindProvider } from "overmind-react";
import { config } from "./overmind";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./chakra/theme";

import App from "./components/App";

const overmind = createOvermind(config);

ReactDOM.render(
    <React.StrictMode>
        <OvermindProvider value={overmind}>
            <ChakraProvider theme={theme}>
                <App />
            </ChakraProvider>
        </OvermindProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// import reportWebVitals from "./reportWebVitals";
// reportWebVitals();
