const baseApp = {
    display: "flex",
    flexDirection: "column",
    minH: "100vh",
};

const baseFooter = {
    mt: "auto",
    minH: "20vh",
    width: "100%",
    bg: "teal.900",
    color: "white",
    boxShadow: "0 -1px 10px 5px rgba(0, 0, 0, 0.2)",
};

export const theme = {
    parts: ["app", "footer"],
    baseStyle: {
        app: baseApp,
        footer: baseFooter,
    },
    variants: {},
    defaultProps: {},
};

export default theme;
